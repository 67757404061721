<template>
  <div class="person_background">
    <!--  用div把header包起来是因为header有个height: 100%的属性，防止其把页面撑开了  -->
    <div>
      <Header />
    </div>
    <router-view />
  </div>
</template>

<script>
import Header from '../components/dashboard/Header';
export default {
  name: 'Person',
  components: { Header },
};
</script>

<style scoped>
.person_background {
  background-color: antiquewhite;
  height: 100%;
}
</style>
