<template>
  <div class="header">
    <MoreTabs :show="showMore" />
    <el-row :gutter="0">
      <el-col :md="21">
        <el-menu
          default-active="1"
          mode="horizontal"
          active-text-color="rgb(35, 98, 251)"
          router
        >
          <el-menu-item
            style="padding: 0; margin-right: 20px"
            @click="$router.push('/dashboard/workbench')"
          >
            <div class="logo_box">
              <span style="font-size: 20px; color: black; font-weight: 700">
                COOKIE☆ CRM
              </span>
              <!--              <img-->
              <!--                alt="logo"-->
              <!--                :src="logo"-->
              <!--              />-->
            </div>
          </el-menu-item>
          <el-menu-item
            :index="idx + ''"
            class="el-menu-item-tabs"
            v-for="(item, idx) in items"
            :key="idx"
            style="margin: 0 13px"
            @click="tabClicked(item.tab)"
          >
            <i :class="item.icon" />
            <span>{{ item.tab }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :md="3">
        <el-menu
          default-active="1"
          mode="horizontal"
          active-text-color="rgb(35, 98, 251)"
        >
          <el-menu-item style="padding: 0">
            <i class="el-icon-chat-line-round chat_avatar" />
          </el-menu-item>
          <el-menu-item style="padding: 0 10px">
            <i class="el-icon-bell bell_avatar" />
          </el-menu-item>
          <el-menu-item style="padding: 0">
            <el-dropdown trigger="click" @command="handleCommand">
              <!-- 这里写的相当于一个button，不这样写是element是无法正常展开菜单的 -->
              <span class="el-dropdown-link">
                <div @mouseover="isBlue = true" @mouseleave="isBlue = false">
                  <el-avatar class="user_avatar" size="medium">
                    User
                  </el-avatar>
                  <i
                    class="el-icon-caret-bottom"
                    :style="{ color: isBlue ? '#2362fb' : 'grey' }"
                  />
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="el-icon-user-solid"
                  command="/person/info"
                  >基本信息</el-dropdown-item
                >
                <el-dropdown-item icon="el-icon-question"
                  >帮助中心</el-dropdown-item
                >
                <el-dropdown-item icon="el-icon-switch-button"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MoreTabs from './MoreTabs';
export default {
  name: 'Header',
  components: { MoreTabs },
  methods: {
    tabClicked(tab) {
      if (tab.includes('更多')) {
        this.showMore = !this.showMore;
      } else {
        this.showMore = false;
      }
    },
    handleCommand(command) {
      this.$router.push(command);
    },
  },
  data() {
    return {
      items: [
        // { tab: '选项一', icon: 'el-icon-menu' },
        // { tab: '选项二', icon: 'el-icon-menu' },
        // { tab: '选项三', icon: 'el-icon-menu' },
        // { tab: '选项四', icon: 'el-icon-menu' },
        // { tab: '选项五', icon: 'el-icon-menu' },
        // { tab: '更多', icon: 'el-icon-menu' },
      ],
      showMore: false,
      isBlue: false,
    };
  },
};
</script>

<style>
.header {
  height: 100%;
  background-color: white;
}
.logo_box {
  height: 100%;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 17px;
}
.logo_box > img {
  height: 40px;
  width: 70px;
}
.el-menu-item-tabs {
  width: 85px !important;
  /*border-bottom: 3px solid rgb(35, 98, 251) !important; */
  padding: 0;
  text-align: center;
  border-bottom-width: 3px !important;
}
.el-menu-item:hover {
  color: rgb(35, 98, 251) !important;
}
.chat_avatar,
.bell_avatar {
  background-color: white;
}
.chat_avatar:hover {
  color: #2362fb;
}
.bell_avatar:hover {
  color: #2362fb;
}
.user_avatar {
  background-color: #2362fb;
}
.tooltip_box {
  background-color: red;
}
</style>
