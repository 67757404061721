<template>
  <div class="more_tabs_box" v-show="show">
    <el-card style="border-bottom: none" class="box-card" shadow="never">
      <div class="more_tabs_title" slot="header">
        <span>全部应用</span>
      </div>
      <div>
        <el-row>
          <el-col :md="2" v-for="i in 8" :key="i" style="cursor: pointer">
            <div class="more_tabs_icon_box">
              <div class="more_tabs_icon">
                <i class="el-icon-s-home" />
              </div>
            </div>
            <div class="more_tabs_icon_text">
              <span>客户管理</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card style="padding-bottom: 20px" class="box-card" shadow="never">
      <div class="more_tabs_title" slot="header">
        <span>置顶应用</span>
      </div>
      <div>
        <el-row>
          <el-col :md="2" v-for="i in 8" :key="i" style="cursor: pointer">
            <div class="more_tabs_icon_box">
              <div class="more_tabs_icon">
                <i class="el-icon-s-home" />
              </div>
            </div>
            <div class="more_tabs_icon_text">
              <span>客户管理</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'MoreTabs',
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.el-card {
  border: none !important;
}
.more_tabs_box {
  position: absolute;
  background-color: white;
  z-index: 114;
  width: 1315px;
  top: 63px;
  left: 210px;
}
.more_tabs_icon {
  background-color: #f0f0f0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: #2362fb;
  font-size: 17px;
}
.more_tabs_icon_box {
  display: flex;
  justify-content: center;
}
.more_tabs_icon_text {
  text-align: center;
  font-size: 13px;
  padding-top: 5px;
}
.more_tabs_title {
  font-weight: 700;
  font-size: 13px;
}
</style>
